define("discourse/plugins/discourse-characters-required/discourse/components/characters-required", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["characters-required"],
    showRequired: missing => missing > 0
  }, [["field", "showRequired", [(0, _decorators.default)("composer.missingReplyCharacters")]]]));
});