define("discourse/plugins/discourse-characters-required/discourse/connectors/after-d-editor/show-required", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender: args => args && args.editorType === "composer"
  };
});