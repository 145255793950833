define("discourse/plugins/discourse-characters-required/discourse/templates/components/characters-required", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if showRequired}}
    {{i18n "characters_required" count=composer.missingReplyCharacters}}
  {{/if}}
  */
  {
    "id": "IVTBd6ZY",
    "block": "[[[41,[30,0,[\"showRequired\"]],[[[1,\"  \"],[1,[28,[35,1],[\"characters_required\"],[[\"count\"],[[30,0,[\"composer\",\"missingReplyCharacters\"]]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showRequired` property path was used in the `discourse/plugins/discourse-characters-required/discourse/templates/components/characters-required.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showRequired}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `composer` property path was used in the `discourse/plugins/discourse-characters-required/discourse/templates/components/characters-required.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.composer}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-characters-required/discourse/templates/components/characters-required.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});